<template>
    <div>
        <a-row :gutter="8">
            <a-col :span="showWaterfactoryList ? 18 : 18">
                <a-card :title="title" :body-style="{padding: '5px 5px'}">
                    <!--<div  slot="extra">-->
                        <!--<a-radio-group v-model="mapType" button-style="solid">-->
                            <!--<a-radio-button value="a">-->
                                <!--地图模式-->
                            <!--</a-radio-button>-->
                            <!--<a-radio-button value="b">-->
                                <!--3D组态-->
                            <!--</a-radio-button>-->
                        <!--</a-radio-group>-->
                    <!--</div>-->
                    <a-row>
                        <a-col :span="6" style="text-align: center;">
                            <div class="sml-title">瞬时流量</div>
                            <div class="sml-num">{{homeData.ll}}m³/H</div>
                        </a-col>
                        <a-col :span="6" style="text-align: center;">
                            <div class="sml-title">今日供水量</div>
                            <div class="sml-num">{{homeData.dayWater}}m³</div>
                        </a-col>
                        <a-col :span="6" style="text-align: center;">
                            <div class="sml-title">本月供水量</div>
                            <div class="sml-num">{{homeData.monthWater}}m³</div>
                        </a-col>
                        <a-col :span="6" style="text-align: center;">
                            <div class="sml-title">当年供水量</div>
                            <div class="sml-num">{{homeData.yearWater}}m³</div>
                        </a-col>
                    </a-row>
                    <div style="margin-top:5px;height: 700px;">
                        <!--<div v-show="mapType == 'b'" style="width:100%;height: 700px;">-->
                            <!--<iframe :src="'/zwvr/index.html?id=' + 6" width="100%" height="700px" :frameborder="0"/>-->
                        <!--</div>-->
                        <div v-show="mapType == 'a'" style="height: 700px;">
                            <my-map  ref="myMap" v-if="loading" :adapter="adapter" :zoom="12" :center="convert([ 105.312515, 37.450750 ])" height="700px" projection="EPSG:3857">
                                <my-map-placement placement="right-top"  :margin="[10,50,0,0]">
                                    <a-radio-group  v-model="showData" button-style="solid"  @change="showTypeChange">
                                        <a-radio-button value="a">
                                            预警点位
                                        </a-radio-button>
                                        <a-radio-button value="b">
                                            全部点位
                                        </a-radio-button>
                                    </a-radio-group>
                                </my-map-placement>
                                <my-map-drawer width="300px" height="100px" placement="left" collapsed close-on-click-outside >
                                    <my-map-layers :layers="layers" :zIndex="1"></my-map-layers>
                                </my-map-drawer>
                                <!--<my-map-placement placement="left-top"  :margin="[100,0,0,10]">
                                   <a-radio-group  v-model="showArea" button-style="solid"  @change="showAreaChange">
                                      <a-radio-button value="a">
                                        显示地名
                                      </a-radio-button>
                                      <a-radio-button value="b">
                                        不显示地名
                                      </a-radio-button>
                                    </a-radio-group>
                                </my-map-placement>-->
                                <!----><my-map-pointer placement="right-bottom"></my-map-pointer>
                                <my-map-zoom theme="light" placement="right-top"></my-map-zoom>
                                <my-map-geo :json="geo"  :zIndex="2"  @ready="geoReadyHandle"></my-map-geo>
                                <my-map-scale ></my-map-scale>
                                <my-map-overview :collapsed="true"></my-map-overview>
                                <my-map-marker :zIndex="2" v-if="markers" :data="markers" multiple :src="marker1" :scale="0.22">
                                </my-map-marker>
                                <my-map-marker  :src="pin" :zIndex="2" v-if="zuitaimarkers" :data="zuitaimarkers" multiple>
                                </my-map-marker>
                                <div v-for="(item) in markers" :key="item.id">
                                    <my-map-html :positioning="positioning" :position="item.coordinate">
                                        <div style="background-color: rgba(45,140,240,0);color: #22BCB9;font-weight:bold;margin-top: 15px;padding:5px;border-radius:8px;text-align:center;width:auto;">
                                            {{item.dataName}}
                                        </div>
                                    </my-map-html>
                                </div>


                                <div v-if="mergeDataShow">
                                    <div v-for="(item) in mergeData">
                                        <my-map-popup :position="item.coordinate" v-if="dataType == 'a' ? (item.status || item.datastatus) : true" style="color: #fff;margin-bottom: 30px;" :style="{backgroundColor:item.status ? 'rgba(255,51,0,1)' : item.datastatus ? 'rgba(255,150,0,0.8)' : 'rgba(45,140,240,0.8)'}" :closable="false"  :showArrow="false">
                                            <div v-html="item.dataName+'<br/>'+item.time"></div>
                                        </my-map-popup>
                                        <!--<my-map-popup :position="item.coordinate"  v-else-if="item.datastatus"  style="background-color: rgba(255,150,0,0.8);color: #fff;margin-bottom: 30px;" :closable="false"  :showArrow="false">-->
                                            <!--<div v-html="item.dataName+'<br/>'+item.time"></div>-->
                                        <!--</my-map-popup>-->
                                        <!--<my-map-popup :position="item.coordinate" v-if="dataType == 'b' && !item.status && !item.datastatus" style="background-color:rgba(45,140,240,0.8);color: #fff;margin-bottom: 30px;" :closable="false"  :showArrow="false">-->
                                            <!--<div v-html="item.dataName+'<br/>'+item.time"></div>-->
                                        <!--</my-map-popup>-->
                                        <my-map-scatter :coordinate="item.coordinate" v-if="item.status||item.datastatus" :ripple="ripple1" fill="red"></my-map-scatter>

                                    </div>
                                </div>
                                <!--
                                <div v-for="(item) in pressureData" :key="item.id">
                                  <my-map-popup :position="item.coordinate"  v-if="item.datastatus!='1'"  style="background-color: rgba(255,150,0,0.8);color: #fff;margin-bottom: 30px;" :closable="false" theme="dark" :showArrow="false">
                                    <div v-html="item.dataName"></div>
                                  </my-map-popup>
                                  <my-map-scatter :coordinate="item.coordinate" v-if="item.datastatus!='1'" :ripple="ripple1" fill="red"></my-map-scatter>
                                </div>
                                <div v-for="(item) in waterData" :key="item.id">
                                  <my-map-popup :position="item.coordinate" v-if="item.datastatus!='1'" :closable="false" style="background-color: rgba(255,150,0,0.8);color: #fff;margin-bottom: 30px;" :showArrow="false">
                                    <div v-html="item.dataName"></div>
                                  </my-map-popup>
                                  <my-map-scatter :coordinate="item.coordinate" v-if="item.datastatus!='1'" :ripple="ripple1" fill="red"></my-map-scatter>
                                </div>-->
                            </my-map>
                        </div>
                    </div>
                </a-card>
            </a-col>
            <a-col v-if="showWaterfactoryList" :span="6" style="">
                <a-card title="水厂列表">
                    <a-tree
                            style="height: 360px;"
                            :show-line="true"
                            :tree-data="treeData"
                            :replace-fields="replaceFields"
                            :defaultExpandAll="true"
                            @select="onSelect"
                            @check="onCheck"
                            v-if="treeData.length > 0"
                    />
                </a-card>
            </a-col>
            <a-col v-if="!showWaterfactoryList" :span="6">
                <a-card title="设备概况">
                    <div style="height: 220px;" ref="chart2"></div>
                </a-card>
                <a-card title="设备状态" style="margin-top: 10px;">
                    <a slot="extra" @click="toDetail">详情>></a>
                    <div style="display: flex;justify-content: space-between;height: 390px;overflow: hidden;">
                        <a-list :data-source="faultDevices" style="width:100%">
                            <a-list-item slot="renderItem" slot-scope="item, index">
                                <a-list-item-meta>
                                <span slot="title">
                                  <a-badge status="success" v-if="!item.num"/>
                                  <a-badge status="error" v-else/>
                                {{ item.title }}
                                </span>
                                </a-list-item-meta>
                                <div>{{ item.num?item.num+'个故障设备':'全部正常' }}</div>
                            </a-list-item>
                        </a-list>
                    </div>
                </a-card>
            </a-col>
        </a-row>
        <a-row :gutter="8" style="margin-top: 10px;">
            <a-col :span="showWaterfactoryList ? 18 : 24">
                <a-card title="报警趋势">
                    <div style="height: 220px;"  ref="chart1">

                    </div>
                </a-card>
            </a-col>
            <!--<a-col v-if="showWaterfactoryList" :span="6">-->
                <!--<a-card title="设备概况">-->
                    <!--<div style="height: 220px;" ref="chart2"></div>-->
                <!--</a-card>-->
            <!--</a-col>-->
        </a-row>
    </div>
</template>

<script>
  import { fromLonLat } from "ol/proj";
  import Point from 'ol/geom/Point';
  import {Icon, Stroke, Style,Circle,Fill} from 'ol/style';
  import MyMapPointer from '@/components/map/MyMapPointers'
  import {MyMap,MyMapGeo,MyMapLayers,MyMapPlacement,MyMapScale,MyMapOverview,MyMapZoom,MyMapScatter,MyMapLink,MyMapPopup,MyMapMarker,MyMapHtml,MyMapDrawer} from '$ui/map'
  import Image from '@/assets/Image.png'
  import Terrain from '@/assets/terrain.png'
  import TDT from '$ui/map/sources/preview/TDT.png'
  import tianjin from '$ui/charts/geo/province/tianjin.json'
  import henan from '@/assets/henan5.json'
  import TileLayer from 'ol/layer/Tile'
  import XYZ from 'ol/source/XYZ'
  import pin from '$ui/map/sources/pin/red.png'
  import parseStyle from '$ui/map/utils/style'
  import { devicePage,deviceTypeAll,zhibiaoAll,monitorAll,groupAll,waterfactoryAll, findByWfid,homeInfo,getAreaParam,waterfactoryTree } from "@/api/monitor/index";
  import markerPic from '@/assets/marker.png'
  import arrowPng from '$ui/map/sources/arrows/arrow.svg'
  import moment from 'moment'


  import * as echarts from "echarts";

  const setStyle = function (bg = 'rgba(0,0,0,0)') {
    return parseStyle({
      stroke: {width: 10, color: bg}
    })
  }
  /**
   * 内部箭头样式
   * 用于每次渲染集合体的时候，OL 均会调用此函数以获得自定义样式
   * @param feature
   * @param res
   * @returns {undefined[]}
   */
  const arrowStyles =  ((feature, res)=> {
      const featureData = feature.getProperties()
      let geometry = feature.getGeometry().getLineString();
      const ls = featureData.ls
      /*let newData = this.leakData.filter(item => item.fromid==featureData.MidFrom&&item.toid==featureData.MidTo)
      let colorIndex=Math.floor(newData[0].leakPercent/5)
      let color=colorData[colorIndex>4?4:colorIndex]*/

      const value = Math.ceil(225 * parseInt(ls) / 5)
      let styles =  [];
      let color='green'
      if(ls=='0'){
        color=`#0000ff`
        styles =  [
            new Style({
                stroke: new Stroke({
                    color: color,
                    width: 10,
                })
            })
        ];
        return styles;
      }else{
        color=`rgba(225, ${225 - value}, 0, 0.9)`
        styles =  [
            new Style({
                stroke: new Stroke({
                    color: color,
                    width: 10,
                })
            })
        ];
      }
      const length = geometry.getLength();

      var radio = (50 * res) / length;
      var dradio = 1;//投影坐标系，如3857等，在EPSG:4326下可以设置dradio=10000
      for (var i = 0; i <= 1; i += radio) {
        var arrowLocation = geometry.getCoordinateAt(i);
        geometry.forEachSegment(function (end,start ) {
          if (start[0] == end[0] || start[1] == end[1]) return;
          var dx1 = end[0] - arrowLocation[0];
          var dy1 = end[1] - arrowLocation[1];
          var dx2 = arrowLocation[0] - start[0];
          var dy2 = arrowLocation[1] - start[1];
          if (dx1 != dx2 && dy1 != dy2) {
            if (Math.abs(dradio * dx1 * dy2 - dradio * dx2 * dy1) < 0.001) {
                var dx = end[0] - start[0];
                var dy = end[1] - start[1];
                var rotation = Math.atan2(dy, dx);
                styles.push(new Style({
                  geometry: new Point(arrowLocation),
                  image: new Icon({
                    src: arrowPng,
                    anchor: [0.75, 0.5],
                    rotateWithView: false,
                    rotation: -rotation + Math.PI ,
                    scale: .06
                  })
                }));
             }
        }
      });
    }
      return styles;
  })

const data = [
  'Racing car.',
  'Japanese .',
  'Australian .',
  'Man charge.',
  'Los Angel.',
];


  export default {
    name: "survey",
    components: {
      MyMap,
      MyMapGeo,
      MyMapPointer,
      MyMapLayers,
      MyMapPlacement,
      MyMapScale,
      MyMapOverview,
      MyMapZoom,
      MyMapScatter,
      MyMapLink,
      MyMapPopup,
      MyMapMarker,
      MyMapHtml,
      MyMapDrawer
    },
    data (){
      return {
        dataType: 'a',
        showWaterfactoryList: false,
        mapType: 'a',
        title: ' ',
        marker1:markerPic,
        treeData:[],
        replaceFields: {
          children: 'children',
          title: 'name',
          key: 'id'
        },
        adapter: ({LayerGroup})=>{
          let settings=['vec_c', 'vec_w', 'cva_w', 'ibo_w']
          return new LayerGroup({
            layers: settings.map(n =>
                this.createTdtLayer(n)
            )
          })
        },
        layers: [
          {
            title: '交通图',
            adapter: ({LayerGroup})=>{
              let settings=['vec_c', 'vec_w', 'cva_w', 'ibo_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            },
            preview: TDT
          },
          {
            title: '影像图',
            adapter: ({LayerGroup})=>{
              let settings=['img_w', 'cia_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            },
            preview: Image
          },
          {
            title: '地形图',
            adapter: ({LayerGroup})=>{
              let settings=['ter_w', 'cta_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            },
            preview: Terrain
          }
        ],
        geo: henan,
        showArea: 'b',
        loading:true,
        ripple1: {
            color: 'red', // 默认与 fill 参数一致
            period: 2, //  动画周期，秒数
            scale: 8, // 动画中波纹的最大缩放比
            type: 'fill' // 渲染类型 fill 或 stroke
        },
        ripple2: {
            period: 1, //  动画周期，秒数
            scale: 8, // 动画中波纹的最大缩放比
            strokeColor: 'red',
            strokeWidth: 5, // 边框宽度
            type: 'stroke' // 渲染类型 fill 或 stroke
        },
        monitorList: [],
        markers: null,
        zuitaimarkers: null,
        pin,
        pressureData:[],
        waterData:[],
        showData:'a',
        ifShow:true,
        homeData: {},
        positioning:'top-left',
        data,
        faultDevices:[],
        mergeData:[],
        mergeDataShow:false,
        myChart1: null,
        myChart2: null
      }
    },
    created(){
      let areaParam = getAreaParam()
      waterfactoryTree(areaParam).then(r=>{
        this.treeData = [r.data]
        // this.title = this.treeData[0].name
        this.title = '沙坡头区/河南水厂'
      })
      areaParam.getGisData = 1
      homeInfo(areaParam).then(r=>{
        this.homeData = r.data
        this.faultDevices=this.homeData.faultDevices
        this.initChart1();
        this.initChart2();
      })
      monitorAll().then(r=>{
        let monitorList = r.data
        let data1 = monitorList.filter(item => item.zutaiid ==null)
        let data2 = monitorList.filter(item => item.zutaiid !=null)

        this.markers =this.createMarkers(data1)
        this.zuitaimarkers =this.createMarkers(data2)
      })
      let flag1=false
      let flag2=false
      findByWfid(1,2,2).then(r=>{
        let waterData = r.data
        this.waterData =this.createWaterData(waterData)
        flag1=true
        if(flag1&&flag2){
          this.merge()
          this.mergeDataShow=true
        }
      })
      findByWfid(1,3).then(r=>{
        let pressureData = r.data
        this.pressureData =this.createPerssureData(pressureData)
        console.log('pressureData==='+JSON.stringify(this.pressureData))
        flag2=true
        if(flag1&&flag2){
          this.merge()
          this.mergeDataShow=true

        }
      })
    },
    mounted(){
      window.addEventListener('resize', this.resize)
    },
    methods:{
      showTypeChange(e){
        const value = e.target.value
        this.dataType = value
        console.log('value',value)
      },
      convert(obj){
        return fromLonLat(obj)
      },
      resize(){
        if(this.myChart1){
          this.myChart1.resize()
        }
        if(this.myChart2){
          this.myChart2.resize()
        }
      },
      toDetail(){
        this.$router.replace('/index/monitor/device')
      },
      merge(){
        this.mergeData=[]
        for (let i = 0; i < this.markers.length; i++) {
          let d={}
          d.id=this.markers[i].id
          let data=this.pressureData.find(value=>value.id==this.markers[i].id);
          if(data){
            d.pressure=data
          }
          let data1=this.waterData.find(value=>value.id==this.markers[i].id);
          if(data1){
            d.water=data1
          }
          d.status=false
          d.datastatus=false

          if(d.pressure&&d.pressure.status){
              d.status=true
          }
          if(d.pressure&&d.pressure.datastatus){
              d.datastatus=true
          }
          if(d.water&&d.water.status){
              d.status=true
          }
          if(d.water&&d.water.datastatus){
              d.datastatus=true
          }
          let dataName=''
          let time=''
          if(d.pressure){
            dataName+=d.pressure.dataName
            time=d.pressure.time
          }
          if(d.water){
            if(d.pressure)dataName += '<br/>'
            dataName+=d.water.dataName
            time=d.water.time
          }
          d.dataName=dataName
          d.time=time
          d.coordinate=this.markers[i].coordinate
          this.mergeData.push(d)
        }
          console.log(JSON.stringify(this.mergeData))
      },
      createMonth(data) {
        const markers = []
        for (let i = 0; i < data.length; i++) {
          markers.push(data[i].month+'月')
        }
        return markers
      },
      createCount(data) {
        const markers = []
        for (let i = 0; i < data.length; i++) {
          markers.push(data[i].count)
        }
        return markers
      },
      initChart1() {
          let month=this.createMonth(this.homeData.warnMonths)
          let count=this.createCount(this.homeData.warnMonths)
          var chartDom = this.$refs.chart1;
          var myChart = echarts.init(chartDom);
          this.myChart1 = myChart
          var option;
          option =  {
            grid:{
              top: 30,
              bottom:30,
              left:45,
              right:30
            },
            color:[
              'rgb(86,175,255)'
            ],
              xAxis: {
                  type: 'category',
                  data: month
              },
              yAxis: {
                  type: 'value'
              },
              series: [{
                  data: count,
                  type: 'bar',
                itemStyle: {
                  normal: {
                    label: {
                      formatter: "{c}" + "次",
                      show: true,
                      position: "top",
                      textStyle: {
                        fontWeight: "bolder",
                        fontSize: "12",
                        color: "rgb(89,175,255)"
                      }
                    },
                  }
                  }
              }]
          };

          myChart.setOption(option);
      },
      initChart2(){
          console.log('this.homeData===='+JSON.stringify(this.homeData.deviceStatusNum))
          let deviceStatusNum=this.homeData.deviceStatusNum
          var chartDom = this.$refs.chart2;
          var myChart = echarts.init(chartDom);
          this.myChart2 = myChart
          var total = this.homeData.deviceStatusNum.total
          var option;
          option =  {
              tooltip: {
                  trigger: 'item'
              },
              legend: {
                  top: '0',
                  left: 'center'
              },
            color:[ //自定义的颜色
              'rgb(77,203,115)',
              'rgb(250,211,55)',
              'rgb(240,72,100)'
            ],
              series: [
                  {
                      name: '设备',
                      type: 'pie',
                    center: ['50%', '60%'],
                      radius: ['40%', '70%'],
                      avoidLabelOverlap: false,
                      itemStyle: {
                          borderRadius: 10,
                          borderColor: '#fff',
                          borderWidth: 2
                      },
                    label: {
                      normal: {
                        show: true,
                        position: 'center',
                        color:'#4c4a4a',
                        formatter: '{total|' + total +'}'+ '\n\r' + '{active|设备总数}',
                        rich: {
                          total:{
                            fontSize: 16,
                            fontFamily : "微软雅黑",
                            color:'#454c5c'
                          },
                          active: {
                            fontFamily : "微软雅黑",
                            fontSize: 16,
                            color:'#6c7a89',
                            lineHeight:30,
                          },
                        }
                      },
                      emphasis: {//中间文字显示
                        show: true,
                      }
                    },
                      emphasis: {
                          label: {
                              show: true,
                              fontSize: '40',
                              fontWeight: 'bold'
                          }
                      },
                      labelLine: {
                          show: false
                      },
                      data: [
                          {value: deviceStatusNum.online, name: '正常'},
                          {value: deviceStatusNum.maintenance, name: '维护'},
                          {value: deviceStatusNum.fault, name: '故障'},
                      ]
                  }
              ]
          };

          myChart.setOption(option);
      },
      onSelect(selectedKeys, info) {
        console.log('selected', selectedKeys, info);
      },
      onCheck(checkedKeys, info) {
        console.log('onCheck', checkedKeys, info);
      },
      createTdtLayer(t = 'vec_c') {
        return new TileLayer({
          source: new XYZ({
            url: 'http://t{0-7}.tianditu.gov.cn/DataServer?T=' + t + '&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
          })
        })
      },
      showAreaChange(val){
        console.log('-----------------------------'+val)
        this.loading=false
        if(val.target.value=='a'){
          this.$nextTick(() => {
            /*
            const map = this.$refs.myMap.map
            const layer = this.$refs.myMap.getLayer()
            if (layer) {
              map.removeLayer(layer)
            }
            if (this.adapter) {
              this.adapter.__MY_LAYER__ = true
              map.addLayer(this.adapter)
            }*/

            this.adapter=({LayerGroup})=>{
              let settings=['img_w', 'cia_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            }

            this.layers= [
              {
                title: '影像图',
                adapter: ({LayerGroup})=>{
                  let settings=['img_w', 'cia_w']
                  return new LayerGroup({
                    layers: settings.map(n =>
                        this.createTdtLayer(n)
                    )
                  })
                },
                preview: Image
              },
              {
                title: '交通图',
                adapter: ({LayerGroup})=>{
                  let settings=['vec_c', 'vec_w', 'cva_w', 'ibo_w']
                  return new LayerGroup({
                    layers: settings.map(n =>
                        this.createTdtLayer(n)
                    )
                  })
                },
                preview: TDT
              },
              {
                title: '地形图',
                adapter: ({LayerGroup})=>{
                  let settings=['ter_w', 'cta_w']
                  return new LayerGroup({
                    layers: settings.map(n =>
                        this.createTdtLayer(n)
                    )
                  })
                },
                preview: Terrain
              }
            ]
            this.loading=true
          })
        }else{
          this.$nextTick(() => {
            this.adapter={
                    type: 'XYZ',
                    // url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                     url: 'https://t' + Math.round(Math.random() * 7) + '.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
            }

            this.layers= [
              {
                title: '影像图',
                adapter: {
                    type: 'XYZ',
                    // url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                    url: 'http://webst0{1-4}.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}'
                },
                preview: Image
              },
              {
                title: '交通图',
                adapter: {
                    type: 'XYZ',
                    // url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                     url: 'https://t' + Math.round(Math.random() * 7) + '.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                },
                preview: TDT
              },
              {
                title: '地形图',
                adapter: {
                    type: 'XYZ',
                    // url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=ter_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                    url: 'https://t' + Math.round(Math.random() * 7) + '.tianditu.gov.cn/DataServer?T=ter_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                },
                preview: Terrain
              }
            ]
            this.loading=true
          })
        }
      },
      showDataChange(val){
        if(val.target.value=='a'){
          this.ifShow=true
        }else{
          this.ifShow=false
        }
      },
      createMarkers(data) {
        const markers = []
        for (let i = 0; i < data.length; i++) {
          markers.push({
            coordinate: this.convert([data[i].lon,data[i].lat]),
            dataName: data[i].name,
            id: data[i].id,
          })
        }
        return markers
      },
      createWaterData(data) {
        const markers = []
        for (let i = 0; i < data.length; i++) {
          let deviceDetails=data[i].deviceDetails
          if(deviceDetails.length>0){
            let datastatus1=false
            let status1=false
            for (let i = 0; i < deviceDetails.length; i++) {
              if(deviceDetails[i].datastatus==2){
                datastatus1=true
              }
              if(deviceDetails[i].status>1){
                status1=true
              }
            }
            markers.push({
              coordinate: this.convert([data[i].lon,data[i].lat]),
              dataName: deviceDetails.map(item => item.name+'流量：'+item.ll+'m³   '+(item.warnning?'<b>['+item.warnning.descr+']</b>':'')).join("<br/>") ,
              time: '更新时间：'+moment(deviceDetails[0].time).format('YYYY-MM-DD HH:mm') ,
              id: data[i].id,
              datastatus:datastatus1,
              status:status1
            })
          }
        }
        return markers
      },
      createPerssureData(data) {
        const markers = []
        for (let i = 0; i < data.length; i++) {
          let deviceDetails=data[i].deviceDetails
          if(deviceDetails.length>0){
            let datastatus1=false
            let status1=false
            for (let i = 0; i < deviceDetails.length; i++) {
              if(deviceDetails[i].datastatus==2){
                datastatus1=true
              }
              if(deviceDetails[i].status>1){
                status1=true
              }
            }
            markers.push({
              coordinate: this.convert([data[i].lon,data[i].lat]),
              dataName: deviceDetails.map(item => item.name+'水压：'+item.pressure.toFixed(3)+'Mpa   '+(item.warnning?'<b>['+item.warnning.descr+']</b>':'')).join("<br/>") ,
              time: '更新时间：'+moment(deviceDetails[0].time).format('YYYY-MM-DD HH:mm') ,
              id: data[i].id,
              datastatus:datastatus1,
              status:status1
            })
          }
        }
        return markers
      },
      geoReadyHandle(geo) {
        const features = geo.getFeatures()
        features.forEach((feature) => {
          const featureData = feature.getProperties()
          const ls = featureData.ls
          if(ls){
              feature.setStyle(arrowStyles)

            /*
            if(ls=='0'){
              const styleObj = setStyle(`#ff9900`)
              feature.setStyle(styleObj)
            }else{
              const value = Math.ceil(225 * parseInt(ls) / 5)
              const styleObj = setStyle(`rgba(225, ${225 - value}, 0, 0.9)`)
              feature.setStyle(styleObj)
            }*/
          }
        })
      }
    }
  };
</script>

<style scoped lang="less">
    .sml-title{
        font-size: 14px;
    }
    .sml-num{
        font-size: 20px;
        font-weight: bold;
    }
</style>
